import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },

  {
    path: "dashboard",
    loadChildren: () =>
      import("./Pages/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },

  {
    path: "login",
    loadChildren: () =>
      import("./Pages/login/login.module").then((m) => m.LoginPageModule),
  },

  {
    path: "ponto",
    loadChildren: () =>
      import("./Pages/ponto/ponto.module").then((m) => m.PontoPageModule),
  },

  {
    path: "ponto-tabuleta",
    loadChildren: () =>
      import("./Pages/ponto-tabuleta/ponto-tabuleta.module").then(
        (m) => m.PontoTabuletaPageModule
      ),
  },

  /*
  {
    path: "tabuleta",
    loadChildren: () =>
      import("./Pages/tabuleta/tabuleta.module").then(
        (m) => m.TabuletaPageModule
      ),
  },
  */

  {
    path: "reserva",
    loadChildren: () =>
      import("./Pages/reserva/reserva.module").then((m) => m.ReservaPageModule),
  },

  // {
  //   path: "maps",
  //   loadChildren: () =>
  //     import("./pages/maps/maps.module").then((m) => m.MapsPageModule),
  // },
  {
    path: "reserva-list",
    loadChildren: () =>
      import("./Pages/reserva-list/reserva-list.module").then(
        (m) => m.ReservaListPageModule
      ),
  },
  {
    path: "consulta",
    loadChildren: () =>
      import("./Pages/consulta/consulta.module").then(
        (m) => m.ConsultaPageModule
      ),
  },
  {
    path: "consulta-resultado",
    loadChildren: () =>
      import("./Pages/consulta-resultado/consulta-resultado.module").then(
        (m) => m.ConsultaResultadoPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
